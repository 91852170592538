import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ press }) => {
  if (!press || press.isHide) return null

  return (
    <div className={styles.press}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(press.image))} className={styles.imageSrc} alt={press.image?.caption} />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>{press.institutionsTitle}</h2>
          <div className={styles.institutions}>
            {press.institutions &&
              press.institutions.map((item, index) => (
                <div className={styles.institutionsItem} key={item._key}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    className={styles.institutionsLogo}
                    alt={item.image?.caption}
                  />
                </div>
              ))}
          </div>
          <h2 className={styles.title}>{press.title}</h2>
          <div className={styles.items}>
            {press.items &&
              press.items.map((item, index) => (
                <div className={styles.item} key={item._key}>
                  <img {...srcSetProps(sanityImageUrl(item.image))} className={styles.logo} alt={item.image?.caption} />
                  <p className={styles.text}>{item.text}</p>
                  <a href={item.buttonUrl} target="_blank" className={styles.link}>
                    {item.buttonText}
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
