import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import Header from '../components/MainPage/Header'
import Press from '../components/MainPage/Press'
import Clients from '../components/MainPage/Clients'
import Testosterone from '../components/MainPage/Testosterone'
import BloodFlow from '../components/MainPage/BloodFlow'
import WeightLoss from '../components/MainPage/WeightLoss'
import HairGrowth from '../components/MainPage/HairGrowth'
import HealthCare from '../components/MainPage/HealthCare'
import Faq from '../components/MainPage/Faq'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      {data.productSchemas && data.productSchemas.map(schema => <ProductSchema {...schema} />)}
      <Header header={data.header} />
      <Press press={data.press} />
      <Clients clients={data.clients} />
      <Testosterone testosterone={data.testosterone} />
      <BloodFlow bloodFlow={data.bloodFlow} />
      <WeightLoss weightLoss={data.weightLoss} />
      <HairGrowth hairGrowth={data.hairGrowth} />
      <HealthCare healthCare={data.healthCare} />
      <Faq faq={data.faq} />
    </Layout>
  )
}
