import React from 'react'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ bloodFlow }) => {
  if (!bloodFlow || bloodFlow.isHide) return null

  return (
    <div className={styles.bloodFlow}>
      <img {...srcSetProps(sanityImageUrl(bloodFlow.image))} className={styles.image} alt={bloodFlow.image?.caption} />
      <img
        {...srcSetProps(sanityImageUrl(bloodFlow.mobileImage))}
        className={styles.imageMobile}
        alt={bloodFlow.mobileImage?.caption}
      />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <p className={styles.titleSmall}>{bloodFlow.smallTitle}</p>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: bloodFlow.title }} />
          <p className={styles.price} dangerouslySetInnerHTML={{ __html: bloodFlow.price }} />
          <div className={styles.buttons}>
            <a
              href={bloodFlow.getStartedButtonUrl}
              onClick={urlWithSearchParamsHandler}
              className={styles.buttonsStarted}
            >
              {bloodFlow.getStartedButtonText}
            </a>
            <Link className={styles.buttonsMore} to={bloodFlow.learnMoreButtonUrl}>
              {bloodFlow.learnMoreButtonText}
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{bloodFlow.ingredientsTitle}</p>
              <ul className={styles.infoList}>
                {bloodFlow.ingredientsItems &&
                  bloodFlow.ingredientsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.ingredient}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={cx(styles.infoColumn, styles.infoColumnBenefits)}>
              <p className={styles.infoTitle}>{bloodFlow.benefitsTitle}</p>
              <ul className={cx(styles.infoList, styles.infoListPoints)}>
                {bloodFlow.benefitsItems &&
                  bloodFlow.benefitsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      {item.benefit}
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styles.infoColumn}>
              <p className={styles.infoTitle}>{bloodFlow.productsTitle}</p>
              <ul className={styles.infoList}>
                {bloodFlow.productsItems &&
                  bloodFlow.productsItems.map(item => (
                    <li className={styles.infoItem} key={item._key}>
                      <Link className={styles.infoLink} to={item.productUrl}>
                        {item.productText}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
